import { Block,
    SpotlightWaitlistConstants,
    CampaignIframeOverlayConstants
   } from './exportComponent';   

const ContentProperty = (entity) => {
    
    const block = new Block(entity);
    const title = block.getFieldValue(SpotlightWaitlistConstants.title);
    const body = block.getFieldValue(SpotlightWaitlistConstants.body);
    const legalText = block.getFieldValue(SpotlightWaitlistConstants.legalText);
    const TopicTitle = block.getFieldValue(SpotlightWaitlistConstants.TopicTitle);
    const rawTopicList = block.getFieldValue(SpotlightWaitlistConstants.topicList);
    const anchorId = block.getFieldValue(SpotlightWaitlistConstants.anchorId);
    const closeModalLabel = block.getFieldValue(SpotlightWaitlistConstants.closeModalLabel)?.fields?.text;
    const mobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAsset);
    const backgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.backgroundAsset);
    const thankYouCallToAction = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToAction);
    const descriptionFormFieldText = block.getFieldValue(SpotlightWaitlistConstants.descriptionFormFieldText);
    const ctaLabel = block.getFieldValue(SpotlightWaitlistConstants.callToActionLabel);
    const formLabels = block.getFieldValue(SpotlightWaitlistConstants.formLabels);
    const emailDisclaimerTop = block.getFieldValue(SpotlightWaitlistConstants.emailDisclaimerTop);
    const isImplicitTCAgree = block.getFieldValue(SpotlightWaitlistConstants.implicitTCAgree) || false;
    const isInterstitialPopup = block.getFieldValue(SpotlightWaitlistConstants.isInterstitialPopup);
    const countrySelectErrorMessage = block.getFieldValue(SpotlightWaitlistConstants.countrySelectErrorMessage);
    const emailExceedLimitLabel = block.getFieldValue(SpotlightWaitlistConstants.emailLimitExceedLabel)?.fields?.text;
    const thankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouTitleLabel)?.fields?.text;
    const thankYouBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.thankYouBackgroundAsset);
    const thankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouDescriptionLabel)?.fields?.text;
    const screen2MobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAssetSecondScreen);
    const expiryHours = block.getFieldValue(CampaignIframeOverlayConstants.expiryHours);
    const backgroundColor = block.getFieldValue(SpotlightWaitlistConstants.backgroundColor);
    const modalLabelFirst = block.getFieldValue(SpotlightWaitlistConstants.modalLabelFirst);
    const modalLabelSecond = block.getFieldValue(SpotlightWaitlistConstants.modalLabelSecond);
    const emailMarketText = block.getFieldValue(SpotlightWaitlistConstants.emailMarketText);
    const addressTypeCode = block.getFieldValue(SpotlightWaitlistConstants.addressTypeCode);
    const callToPreOrderActionLabel = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLabel);
    const firstTraitValue = block?.props?.fields?.traitvalues?.traitValue[0]?.value;
    const secondTraitValue = block?.props?.fields?.traitvalues?.traitValue[1]?.value;
    const surveyResponse = block?.props?.fields?.traitvalues?.surveyResponse[0]?.answer;
    const countrySelectLabel = block.getFieldValue(SpotlightWaitlistConstants.countrySelectLabel);

        
    return {
        title,
        body,
        anchorId,
        closeModalLabel,
        mobileBackgroundAsset,
        backgroundAsset,
        thankYouCallToAction,
        TopicTitle,
        rawTopicList,
        descriptionFormFieldText,
        ctaLabel,
        formLabels,
        emailDisclaimerTop,
        isImplicitTCAgree,
        legalText,
        isInterstitialPopup,
        countrySelectErrorMessage,
        emailExceedLimitLabel,
        thankYouTitleLabel,
        thankYouBackgroundAsset,
        thankYouDescriptionLabel,
        screen2MobileBackgroundAsset,
        expiryHours,
        backgroundColor,
        modalLabelFirst,
        modalLabelSecond,
        emailMarketText,
        callToPreOrderActionLabel,
        addressTypeCode,
        firstTraitValue,
        secondTraitValue,
        surveyResponse,
        countrySelectLabel
    }
}

export default ContentProperty;